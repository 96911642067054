<template>
  <div>
    <div class="fancy-portfolio-two lg-container">
      <div class="container">
        <div>
          <div
            class="mixitUp-container gutter-space-one d-flex flex-wrap pt-100"
          >
            <div
              v-for="(item, imageIndex) in p_items"
              :key="item.id"
              :class="`mix ${item.large_size ? 'mixL' : ''}`"
              data-aos="fade-right"
            >
              <div class="portfolio-block-two position-relative">
                <img
                  :src="item.img"
                  alt=""
                  class="w-100 h-100 tran4s img-meta"
                  @click="showImg(imageIndex)"
                  style="cursor: pointer"
                />
                <button
                  @click="showImg(imageIndex)"
                  v-if="!item.video"
                  class="fancybox d-flex align-items-center justify-content-center"
                >
                  <i class="fa fa-arrows-alt" aria-hidden="true"></i>
                </button>
                <button
                  v-else
                  data-bs-toggle="modal"
                  data-bs-target="#videoModal"
                  class="fancybox d-flex align-items-center justify-content-center"
                >
                  <i class="fa fa-play" aria-hidden="true"></i>
                </button>
              </div>
              <!-- /.portfolio-block-two -->
            </div>
          </div>
          <!-- /.mixitUp-container -->
        </div>
      </div>
    </div>

    <!-- image light box start -->
    <vue-easy-lightbox
      :visible="visible"
      :imgs="p_items?.slice(0, 17).map((img) => img.img)"
      :index="index"
      @hide="handleHide"
    >
    </vue-easy-lightbox>
    <!-- image light box end -->

    <!-- VideoModal start -->
    <!-- <VideoModal videoId="aXFSJTjVjw0" /> -->
    <!-- VideoModal end -->
  </div>
</template>

<script>
import portfolioMixin from "@/mixin/portfolio-mixin";
import VueEasyLightbox from "vue-easy-lightbox";
// import VideoModal from "../common/Modal/VideoModal.vue";

export default {
  name: "FancyPortfolio",
  mixins: [portfolioMixin],
  components: {
    VueEasyLightbox,
    // VideoModal,
  },
  data() {
    return {
      p_items: [],
      isActive: "all",
      visible: false,
      index: 0,
      loadMoreIndex: 0,
    };
  },
  methods: {
    handleItem(category) {
      this.isActive = category;
      if (category === "all") {
        return (this.p_items = this.portfolioItems.slice(0, 17));
      } else {
        return (this.p_items = this.portfolioItems.filter(
          (item) => item.category === category
        ));
      }
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },

    loadMore() {
      this.p_items = this.portfolioItems.slice(
        this.loadMoreIndex,
        this.loadMoreIndex + 3
      );

      this.loadMoreIndex = this.loadMoreIndex + 3;

      return this.p_items;
    },
  },
  mounted() {
    this.p_items = this.portfolioItems.slice(0, 17);
  },
};
</script>
