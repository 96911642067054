<template>
  <div id="#" class="hero-banner-one">
    <div class="hero-upper-container background-wa" style="padding: 7rem 0">
      <div class="container">
        <div class="row">
          <div
            class="col-xl-8 col-lg-10 m-auto"
            style="display: flex; align-items: center; justify-content: center"
          >
            <img width="300px" src="@/assets/images/fj_logo_text.png" alt="" />
          </div>
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
          "
        >
          <a
            class="theme-btn-one mt-50 md-mt-30 wa-external-link-btn wa-white"
            style="text-transform: uppercase"
            href="https://www.ferienundwohnen.de/mieten/ferienwohnung-fw29626"
            target="_blank"
          >
            * Jetzt buchen
          </a>
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 3rem;
            color: #fff;
          "
        >
          <p class="wa-font-3" style="font-size: 2.25rem; line-height: 3rem">
            Hier auf VIR finden Sie alles was ein maritimes Urlauberherz
            begehrt!
          </p>
        </div>
      </div>
    </div>
    <!-- /.hero-upper-container -->
  </div>
</template>

<script>
export default {
  name: "HeroArea",
  methods: {
    onSubmit() {
      console.log("Submitted");
    },
  },
};
</script>

<style>
.background-wa {
  background-image: url("./../../assets/images/img_fj/outside/webp/banner.webp");
  background-position: center;
  background-size: cover;
  filter: opacity(1.2);
}
</style>
