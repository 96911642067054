export default {
  data() {
    return {
      portfolioItems: [
        //  v1
        {
          id: 1,
          img: require(`@/assets/images/img_fj/webp/sleep1.webp`),
        },
        {
          id: 2,
          img: require(`@/assets/images/img_fj/webp/sleep2.webp`),
        },
        {
          id: 3,
          img: require(`@/assets/images/img_fj/webp/sleep3.webp`),
        },
        {
          id: 4,
          img: require(`@/assets/images/img_fj/webp/sleep4.webp`),
        },
        // {
        //   id: 5,
        //   img: require(`@/assets/images/img_fj/webp/sleep5.webp`),
        // },
        {
          id: 6,
          img: require(`@/assets/images/img_fj/webp/bath1.webp`),
        },
        {
          id: 7,
          img: require(`@/assets/images/img_fj/webp/bath2.webp`),
        },
        {
          id: 8,
          img: require(`@/assets/images/img_fj/webp/living1.webp`),
        },
        {
          id: 9,
          img: require(`@/assets/images/img_fj/webp/living2.webp`),
        },
        {
          id: 10,
          img: require(`@/assets/images/img_fj/webp/kitchen.webp`),
        },
        {
          id: 11,
          img: require(`@/assets/images/img_fj/webp/terrace.webp`),
        },
      ],
    };
  },
};
