<template>
  <div class="contact-style-two">
    <div class="container">
      <div class="form-style-classic mt-200 md-mt-80">
        <form
          @submit.prevent="onSubmit($event)"
          id="contact-form"
          data-bs-toggle="validator"
        >
          <div class="row controls">
            <div
              class="col-md-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div class="input-group-meta form-group mb-60">
                <label style="font-size: 1.25rem">Vorname</label>
                <input
                  type="text"
                  name="Fname"
                  required="required"
                  data-error="Vorname ist ein Pflichtfeld!"
                />
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-md-6" data-aos="fade-left" data-aos-duration="1200">
              <div class="input-group-meta form-group mb-60">
                <label style="font-size: 1.25rem">Nachname</label>
                <input
                  type="text"
                  name="Lname"
                  required="required"
                  data-error="Nachname ist ein Pflichtfeld!"
                />
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-12" data-aos="fade-up" data-aos-duration="1200">
              <div class="input-group-meta form-group mb-60">
                <label style="font-size: 1.25rem">Email-Adresse</label>
                <input
                  type="email"
                  name="email"
                  required="required"
                  data-error="Email ist ein Pflichtfeld!"
                />
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-12" data-aos="fade-up" data-aos-duration="1200">
              <div class="input-group-meta form-group lg mb-40">
                <label style="font-size: 1.25rem">Ihre Nachricht</label>
                <textarea
                  name="message"
                  required="required"
                  data-error="Eine Nachricht solltest du uns auch hinterlassen ..."
                ></textarea>
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-12" data-aos="fade-up" data-aos-duration="1200">
              <div
                style="display: flex; flex-direction: row; align-items: center"
              >
                <button
                  class="theme-btn-one"
                  :disabled="status === 'success'"
                  style="text-transform: uppercase"
                >
                  Senden
                </button>
                <div style="margin-left: 1rem">
                  <div v-if="status === 'error'" style="color: red">
                    {{ message }}
                  </div>
                  <div v-if="status === 'success'" style="color: green">
                    {{ message }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- /.form-style-classic -->
    </div>
    <div
      class="mt-80 md-mt-80"
      id="map"
      style="height: 450px; width: 100%"
    ></div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";

export default {
  name: "ContactStyle",
  data() {
    return {
      status: "",
      message: "",
    };
  },

  methods: {
    setupLeafletMap() {
      const center = [44.30283, 15.101345];
      const map = L.map("map").setView(center, 13);

      L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
      }).addTo(map);

      map.attributionControl.setPrefix("");

      delete L.Icon.Default.prototype._getIconUrl;

      L.Icon.Default.mergeOptions({
        iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
        iconUrl: require("leaflet/dist/images/marker-icon.png"),
        shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
      });

      L.marker(center).addTo(map);
    },

    async onSubmit(event) {
      const data = {
        fname: event.target[0].value,
        lname: event.target[1].value,
        email: event.target[2].value,
        message: event.target[3].value,
      };

      const response = await fetch("/api/v1/sendmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      response
        .json()
        .then((data) => {
          this.status = data.status;
          this.message = data.message;
        })
        .catch(() => {
          this.status = "error";
          this.message =
            "Es konnte keine Verbindung zum Server hergestellt werden. Bitte versuchen Sie es später erneut.";
        });
    },
  },

  mounted() {
    this.setupLeafletMap();
  },
};
</script>
