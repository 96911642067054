<template>
  <div class="terms_and_policy" style="border-bottom: none">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a class="nav-link active" data-bs-toggle="tab" href="#opt1"
                >Impressum</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" data-bs-toggle="tab" href="#opt2"
                >Datenschutzerklärung</a
              >
            </li>
          </ul>
        </div>

        <div class="col-lg-8">
          <!-- Tab panes -->
          <div class="tab-content">
            <div
              v-for="(item, index) in terms_data"
              :key="index"
              :id="item.id"
              :class="`tab-pane fade ${item.active ? 'active show' : ''}`"
            >
              <h2 class="font-gilroy-bold">{{ item.title }}</h2>
              <div class="update-date">{{ item.date }}</div>

              <div v-if="item.id === 'opt1'">
                <div>{{ item.p1 }}</div>
                <div class="pb-25">{{ item.p2 }}</div>
                <h4 style="font-weight: 600">{{ item.comp }}</h4>
                <div>{{ item.add1 }}</div>
                <div>{{ item.add2 }}</div>
                <div class="pb-25">{{ item.add3 }}</div>
                <div style="font-weight: 600">{{ item.add4 }}</div>
                <div class="pb-25">{{ item.add5 }}</div>
                <div style="font-weight: 600">{{ item.add6 }}</div>
                <div>{{ item.add7 }}</div>
                <div class="pb-25">{{ item.add8 }}</div>
                <div style="font-weight: 600">{{ item.add9 }}</div>
                <div>
                  <a :href="item.add10">{{ item.add10 }}</a>
                </div>
                <div>
                  <a :href="item.add11">{{ item.add11 }}</a>
                </div>
              </div>

              <div v-if="item.id === 'opt1'">
                <h3>{{ item.title_1 }}</h3>
                <p>{{ item.text_1 }}</p>

                <h3>{{ item.title_2 }}</h3>
                <p>{{ item.text_2 }}</p>

                <h3>{{ item.title_3 }}</h3>
                <p>{{ item.text_3 }}</p>
              </div>

              <div v-if="item.id === 'opt2'">
                <p>
                  Personenbezogene Daten (nachfolgend zumeist nur „Daten“
                  genannt) werden von uns nur im Rahmen der Erforderlichkeit
                  sowie zum Zwecke der Bereitstellung eines funktionsfähigen und
                  nutzerfreundlichen Internetauftritts, inklusive seiner Inhalte
                  und der dort angebotenen Leistungen, verarbeitet.
                </p>

                <p>
                  Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der
                  Datenschutz-Grundverordnung (nachfolgend nur „DSGVO“ genannt),
                  gilt als „Verarbeitung“ jeder mit oder ohne Hilfe
                  automatisierter Verfahren ausgeführter Vorgang oder jede
                  solche Vorgangsreihe im Zusammenhang mit personenbezogenen
                  Daten, wie das Erheben, das Erfassen, die Organisation, das
                  Ordnen, die Speicherung, die Anpassung oder Veränderung, das
                  Auslesen, das Abfragen, die Verwendung, die Offenlegung durch
                  Übermittlung, Verbreitung oder eine andere Form der
                  Bereitstellung, den Abgleich oder die Verknüpfung, die
                  Einschränkung, das Löschen oder die Vernichtung.
                </p>

                <p>
                  Mit der nachfolgenden Datenschutzerklärung informieren wir Sie
                  insbesondere über Art, Umfang, Zweck, Dauer und
                  Rechtsgrundlage der Verarbeitung personenbezogener Daten,
                  soweit wir entweder allein oder gemeinsam mit anderen über die
                  Zwecke und Mittel der Verarbeitung entscheiden. Zudem
                  informieren wir Sie nachfolgend über die von uns zu
                  Optimierungszwecken sowie zur Steigerung der Nutzungsqualität
                  eingesetzten Fremdkomponenten, soweit hierdurch Dritte Daten
                  in wiederum eigener Verantwortung verarbeiten.
                </p>

                <p>Unsere Datenschutzerklärung ist wie folgt gegliedert:</p>

                <p>
                  I. Informationen über uns als Verantwortliche<br />II. Rechte
                  der Nutzer und Betroffenen<br />III. Informationen zur
                  Datenverarbeitung
                </p>
                <h3 class="wp-block-heading">
                  I. Informationen über uns als Verantwortliche
                </h3>
                <p>
                  Verantwortlicher Anbieter dieses Internetauftritts im
                  datenschutzrechtlichen Sinne ist:
                </p>
                <br />Jonovic, Korina<br />Put-Straze<br />23234, Vir, Kroatien
                <p>E-Mail: info@ferienwohnung-jonovic.de</p>
                <h3 class="wp-block-heading">
                  II. Rechte der Nutzer und Betroffenen
                </h3>
                <p>
                  Mit Blick auf die nachfolgend noch näher beschriebene
                  Datenverarbeitung haben die Nutzer und Betroffenen das Recht
                </p>

                <ul>
                  <li>
                    auf Bestätigung, ob sie betreffende Daten verarbeitet
                    werden, auf Auskunft über die verarbeiteten Daten, auf
                    weitere Informationen über die Datenverarbeitung sowie auf
                    Kopien der Daten (vgl. auch Art. 15 DSGVO);
                  </li>

                  <li>
                    auf Berichtigung oder Vervollständigung unrichtiger bzw.
                    unvollständiger Daten (vgl. auch Art. 16 DSGVO);
                  </li>

                  <li>
                    auf unverzügliche Löschung der sie betreffenden Daten (vgl.
                    auch Art. 17 DSGVO), oder, alternativ, soweit eine weitere
                    Verarbeitung gemäß Art. 17 Abs. 3 DSGVO erforderlich ist,
                    auf Einschränkung der Verarbeitung nach Maßgabe von Art. 18
                    DSGVO;
                  </li>

                  <li>
                    auf Erhalt der sie betreffenden und von ihnen
                    bereitgestellten Daten und auf Übermittlung dieser Daten an
                    andere Anbieter/Verantwortliche (vgl. auch Art. 20 DSGVO);
                  </li>

                  <li>
                    auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie
                    der Ansicht sind, dass die sie betreffenden Daten durch den
                    Anbieter unter Verstoß gegen datenschutzrechtliche
                    Bestimmungen verarbeitet werden (vgl. auch Art. 77 DSGVO).
                  </li>
                </ul>

                <p>
                  Darüber hinaus ist der Anbieter dazu verpflichtet, alle
                  Empfänger, denen gegenüber Daten durch den Anbieter
                  offengelegt worden sind, über jedwede Berichtigung oder
                  Löschung von Daten oder die Einschränkung der Verarbeitung,
                  die aufgrund der Artikel 16, 17 Abs. 1, 18 DSGVO erfolgt, zu
                  unterrichten. Diese Verpflichtung besteht jedoch nicht, soweit
                  diese Mitteilung unmöglich oder mit einem unverhältnismäßigen
                  Aufwand verbunden ist. Unbeschadet dessen hat der Nutzer ein
                  Recht auf Auskunft über diese Empfänger.
                </p>

                <p>
                  <strong
                    >Ebenfalls haben die Nutzer und Betroffenen nach Art. 21
                    DSGVO das Recht auf Widerspruch gegen die künftige
                    Verarbeitung der sie betreffenden Daten, sofern die Daten
                    durch den Anbieter nach Maßgabe von Art. 6 Abs. 1 lit. f)
                    DSGVO verarbeitet werden. Insbesondere ist ein Widerspruch
                    gegen die Datenverarbeitung zum Zwecke der Direktwerbung
                    statthaft.</strong
                  >
                </p>

                <h3 class="wp-block-heading">
                  III. Informationen zur Datenverarbeitung
                </h3>

                <p>
                  Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten
                  werden gelöscht oder gesperrt, sobald der Zweck der
                  Speicherung entfällt, der Löschung der Daten keine
                  gesetzlichen Aufbewahrungspflichten entgegenstehen und
                  nachfolgend keine anderslautenden Angaben zu einzelnen
                  Verarbeitungsverfahren gemacht werden.
                </p>
                <h4 class="jet-listing-dynamic-field__content">Cookies</h4>
                <h5>a) Sitzungs-Cookies/Session-Cookies</h5>
                <p>
                  Wir verwenden mit unserem Internetauftritt sog. Cookies.
                  Cookies sind kleine Textdateien oder andere
                  Speichertechnologien, die durch den von Ihnen eingesetzten
                  Internet-Browser auf Ihrem Endgerät ablegt und gespeichert
                  werden. Durch diese Cookies werden im individuellen Umfang
                  bestimmte Informationen von Ihnen, wie beispielsweise Ihre
                  Browser- oder Standortdaten oder Ihre IP-Adresse, verarbeitet.
                </p>
                <p>
                  Durch diese Verarbeitung wird unser Internetauftritt
                  benutzerfreundlicher, effektiver und sicherer, da die
                  Verarbeitung bspw. die Wiedergabe unseres Internetauftritts in
                  unterschiedlichen Sprachen oder das Angebot einer
                  Warenkorbfunktion ermöglicht.
                </p>
                <p>
                  Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 lit b.)
                  DSGVO, sofern diese Cookies Daten zur Vertragsanbahnung oder
                  Vertragsabwicklung verarbeitet werden.
                </p>
                <p>
                  Falls die Verarbeitung nicht der Vertragsanbahnung oder
                  Vertragsabwicklung dient, liegt unser berechtigtes Interesse
                  in der Verbesserung der Funktionalität unseres
                  Internetauftritts. Rechtsgrundlage ist in dann Art. 6 Abs. 1
                  lit. f) DSGVO.
                </p>
                <p>
                  Mit Schließen Ihres Internet-Browsers werden diese
                  Session-Cookies gelöscht.
                </p>
                <h5>b) Drittanbieter-Cookies</h5>
                <p>
                  Gegebenenfalls werden mit unserem Internetauftritt auch
                  Cookies von Partnerunternehmen, mit denen wir zum Zwecke der
                  Werbung, der Analyse oder der Funktionalitäten unseres
                  Internetauftritts zusammenarbeiten, verwendet.
                </p>
                <p>
                  Die Einzelheiten hierzu, insbesondere zu den Zwecken und den
                  Rechtsgrundlagen der Verarbeitung solcher
                  Drittanbieter-Cookies, entnehmen Sie bitte den nachfolgenden
                  Informationen.
                </p>
                <h5>c) Beseitigungsmöglichkeit</h5>
                <p>
                  Sie können die Installation der Cookies durch eine Einstellung
                  Ihres Internet-Browsers verhindern oder einschränken.
                  Ebenfalls können Sie bereits gespeicherte Cookies jederzeit
                  löschen. Die hierfür erforderlichen Schritte und Maßnahmen
                  hängen jedoch von Ihrem konkret genutzten Internet-Browser ab.
                  Bei Fragen benutzen Sie daher bitte die Hilfefunktion oder
                  Dokumentation Ihres Internet-Browsers oder wenden sich an
                  dessen Hersteller bzw. Support. Bei sog. Flash-Cookies kann
                  die Verarbeitung allerdings nicht über die Einstellungen des
                  Browsers unterbunden werden. Stattdessen müssen Sie insoweit
                  die Einstellung Ihres Flash-Players ändern. Auch die hierfür
                  erforderlichen Schritte und Maßnahmen hängen von Ihrem konkret
                  genutzten Flash-Player ab. Bei Fragen benutzen Sie daher bitte
                  ebenso die Hilfefunktion oder Dokumentation Ihres
                  Flash-Players oder wenden sich an den Hersteller bzw.
                  Benutzer-Support.
                </p>
                <p>
                  Sollten Sie die Installation der Cookies verhindern oder
                  einschränken, kann dies allerdings dazu führen, dass nicht
                  sämtliche Funktionen unseres Internetauftritts vollumfänglich
                  nutzbar sind.
                </p>
                <h4 class="jet-listing-dynamic-field__content">
                  Kontaktanfragen / Kontaktmöglichkeit
                </h4>
                <p>
                  Sofern Sie per Kontaktformular oder E-Mail mit uns in Kontakt
                  treten, werden die dabei von Ihnen angegebenen Daten zur
                  Bearbeitung Ihrer Anfrage genutzt. Die Angabe der Daten ist
                  zur Bearbeitung und Beantwortung Ihre Anfrage erforderlich –
                  ohne deren Bereitstellung können wir Ihre Anfrage nicht oder
                  allenfalls eingeschränkt beantworten.
                </p>
                <p>
                  Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit.
                  b) DSGVO.
                </p>
                <p>
                  Ihre Daten werden gelöscht, sofern Ihre Anfrage abschließend
                  beantwortet worden ist und der Löschung keine gesetzlichen
                  Aufbewahrungspflichten entgegenstehen, wie bspw. bei einer
                  sich etwaig anschließenden Vertragsabwicklung.
                </p>
                <h4 class="jet-listing-dynamic-field__content">Serverdaten</h4>
                <p>
                  Aus technischen Gründen, insbesondere zur Gewährleistung eines
                  sicheren und stabilen Internetauftritts, werden Daten durch
                  Ihren Internet-Browser an uns bzw. an unseren
                  Webspace-Provider übermittelt. Mit diesen sog. Server-Logfiles
                  werden u.a. Typ und Version Ihres Internetbrowsers, das
                  Betriebssystem, die Website, von der aus Sie auf unseren
                  Internetauftritt gewechselt haben (Referrer URL), die
                  Website(s) unseres Internetauftritts, die Sie besuchen, Datum
                  und Uhrzeit des jeweiligen Zugriffs sowie die IP-Adresse des
                  Internetanschlusses, von dem aus die Nutzung unseres
                  Internetauftritts erfolgt, erhoben.
                </p>
                <p>
                  Diese so erhobenen Daten werden vorrübergehend gespeichert,
                  dies jedoch nicht gemeinsam mit anderen Daten von Ihnen.
                </p>
                <p>
                  Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6
                  Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in
                  der Verbesserung, Stabilität, Funktionalität und Sicherheit
                  unseres Internetauftritts.
                </p>
                <p>
                  Die Daten werden spätestens nach sieben Tage wieder gelöscht,
                  soweit keine weitere Aufbewahrung zu Beweiszwecken
                  erforderlich ist. Andernfalls sind die Daten bis zur
                  endgültigen Klärung eines Vorfalls ganz oder teilweise von der
                  Löschung ausgenommen.
                </p>
                <h4 class="jet-listing-dynamic-field__content">
                  Vertragsabwicklung
                </h4>
                <p>
                  Die von Ihnen zur Inanspruchnahme unseres Waren- und/oder
                  Dienstleistungsangebots übermittelten Daten werden von uns zum
                  Zwecke der Vertragsabwicklung verarbeitet und sind insoweit
                  erforderlich. Vertragsschluss und Vertragsabwicklung sind ohne
                  Bereitstellung Ihrer Daten nicht möglich.
                </p>
                <p>
                  Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 lit. b)
                  DSGVO.
                </p>
                <p>
                  Wir löschen die Daten mit vollständiger Vertragsabwicklung,
                  müssen dabei aber die steuer- und handelsrechtlichen
                  Aufbewahrungsfristen beachten.
                </p>
                <p>
                  Im Rahmen der Vertragsabwicklung geben wir Ihre Daten an das
                  mit der Warenlieferung beauftragte Transportunternehmen oder
                  an den Finanzdienstleister weiter, soweit die Weitergabe zur
                  Warenauslieferung oder zu Bezahlzwecken erforderlich ist.
                </p>
                <p>
                  Rechtsgrundlage für die Weitergabe der Daten ist dann Art. 6
                  Abs. 1 lit. b) DSGVO.
                </p>

                <h4 class="jet-listing-dynamic-field__content">
                  Allgemeine Verlinkung auf Profile bei Drittanbietern
                </h4>
                <p>
                  Der Anbieter setzt auf der Website eine Verlinkung auf die
                  nachstehend aufgeführten sozialen Netzwerke ein.
                </p>
                <p>
                  Rechtsgrundlage ist hierbei Art. 6 Abs. 1 lit. f DSGVO. Das
                  berechtigte Interesse des Anbieters besteht an der
                  Verbesserung der Nutzungsqualität der Website.
                </p>
                <p>
                  Die Einbindung der Plugins erfolgt dabei über eine verlinkte
                  Grafik. Erst durch einen Klick auf die entsprechende Grafik
                  wird der Nutzer zu dem Dienst des jeweiligen sozialen
                  Netzwerks weitergeleitet.
                </p>
                <p>
                  Nach der Weiterleitung des Kunden werden durch das jeweilige
                  Netzwerk Informationen über den Nutzer erfasst. Dies sind
                  zunächst Daten wie IP-Adresse, Datum, Uhrzeit und besuchte
                  Seite. Ist der Nutzer währenddessen in seinem Benutzerkonto
                  des jeweiligen Netzwerks eingeloggt, kann der
                  Netzwerk-Betreiber ggf. die gesammelten Informationen des
                  konkreten Besuches des Nutzers dem persönlichen Account des
                  Nutzers zuordnen. Interagiert der Nutzer über einen
                  „Teilen“-Button des jeweiligen Netzwerks, können diese
                  Informationen in dem persönlichen Benutzerkonto des Nutzers
                  gespeichert und ggf. veröffentlicht werden. Will der Nutzer
                  verhindern, dass die gesammelten Informationen unmittelbar
                  seinem Benutzerkonto zugeordnet werden, muss sich der Nutzer
                  vor dem Anklicken der Grafik ausloggen. Zudem besteht die
                  Möglichkeit, das jeweilige Benutzerkonto entsprechend zu
                  konfigurieren.
                </p>
                <p>Folgende soziale Netzwerke sind vom Anbieter verlinkt:</p>

                <p>
                  <strong
                    >Ferienwohnungen, Ferienhäuser günstig mieten von
                    privat</strong
                  >
                </p>
                <p>
                  <a
                    href="https://www.ferienundwohnen.de/"
                    target="_blank"
                    rel="noopener nofollow"
                    >https://www.ferienundwohnen.de/</a
                  >
                </p>
                <p>
                  <a
                    href="https://www.ferienundwohnen.de/datenschutz.php"
                    target="_blank"
                    rel="noopener nofollow"
                    >https://www.ferienundwohnen.de/datenschutz.php</a
                  >
                </p>

                <p>
                  Der Dienst wird für die Buchungs- und Zahlungsabwicklung
                  verwendet.
                </p>

                <h4 class="jet-listing-dynamic-field__content">Google Fonts</h4>
                <p>
                  In unserem Internetauftritt setzen wir Google Fonts zur
                  Darstellung externer Schriftarten ein. Es handelt sich hierbei
                  um einen Dienst der Google Ireland Limited, Gordon House,
                  Barrow Street, Dublin 4, Irland, nachfolgend nur „Google“
                  genannt.
                </p>
                <p>
                  Um die Darstellung bestimmter Schriften in unserem
                  Internetauftritt zu ermöglichen, wird bei Aufruf unseres
                  Internetauftritts eine Verbindung zu dem Google-Server in den
                  USA aufgebaut.
                </p>
                <p>
                  Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser
                  berechtigtes Interesse liegt in der Optimierung und dem
                  wirtschaftlichen Betrieb unseres Internetauftritts.
                </p>
                <p>
                  Durch die bei Aufruf unseres Internetauftritts hergestellte
                  Verbindung zu Google kann Google ermitteln, von welcher
                  Website Ihre Anfrage gesendet worden ist und an welche
                  IP-Adresse die Darstellung der Schrift zu übermitteln ist.
                </p>
                <p>Google bietet unter</p>
                <p>
                  <a
                    href="https://adssettings.google.com/authenticated"
                    target="_blank"
                    rel="noopener nofollow"
                    >https://adssettings.google.com/authenticated</a
                  >
                </p>
                <p>
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noopener nofollow"
                    >https://policies.google.com/privacy</a
                  >
                </p>
                <p>
                  weitere Informationen an und zwar insbesondere zu den
                  Möglichkeiten der Unterbindung der Datennutzung.
                </p>
                <h4 class="jet-listing-dynamic-field__content">
                  OpenStreetMap
                </h4>
                <p>
                  Für Anfahrtsbeschreibungen setzen wir OpenStreetMap, einen
                  Dienst der OpenStreetMap Foundation, St John’s Innovation
                  Centre, Cowley Road, Cambridge, CB 4 0 WS, United Kingdom,
                  nachfolgend nur „OpenStreetMap“ genannt, ein.
                </p>
                <p>
                  Bei Aufruf einer unserer Internetseiten, in die der Dienst
                  OpenStreetMap eingebunden ist, wird durch OpenStreetMap ein
                  Cookie über Ihren Internet-Browser auf Ihrem Endgerät
                  gespeichert. Hierdurch werden Ihre Nutzereinstellungen und
                  Nutzerdaten zum Zwecke der Anzeige der Seite bzw. zur
                  Gewährleistung der Funktionalität des Dienstes OpenStreetMap
                  verarbeitet. Durch diese Verarbeitung kann OpenStreetMap
                  erkennen, von welcher Internetseite Ihre Anfrage gesendet
                  worden ist und an welche IP- Adresse die Darstellung der
                  Anfahrt übermittelt werden soll.
                </p>
                <p>
                  Im Falle einer von Ihnen erteilten Einwilligung für diese
                  Verarbeitung ist Rechtsgrundlage Art. 6 Abs. 1 lit. a DSGVO.
                  Rechtsgrundlage kann auch Art. 6 Abs. 1 lit. f DSGVO sein.
                  Unser berechtigtes Interesse liegt in der Optimierung und dem
                  wirtschaftlichen Betrieb unseres Internetauftritts.
                </p>
                <p>
                  Sofern Sie mit dieser Verarbeitung nicht einverstanden sind,
                  haben Sie die Möglichkeit, die Installation der Cookies durch
                  die entsprechenden Einstellungen in Ihrem Internet-Browser zu
                  verhindern. Einzelheiten hierzu finden Sie vorstehend unter
                  dem Punkt „Cookies“.
                </p>
                <p>OpenStreetMap bietet unter</p>
                <p>
                  <a
                    href="https://wiki.osmfoundation.org/wiki/Privacy_Policy"
                    target="_blank"
                    rel="noopener nofollow"
                    >https://wiki.osmfoundation.org/wiki/Privacy_Policy</a
                  >
                </p>
                <p>
                  weitere Informationen zur Erhebung und Nutzung der Daten sowie
                  zu Ihren Rechten und Möglichkeiten zum Schutz Ihrer
                  Privatsphäre an.
                </p>
                <h4 class="jet-listing-dynamic-field__content">
                  WhatsApp Kontaktaufnahme
                </h4>
                <p>
                  Zur Kontaktaufnahme ermöglicht der Anbieter dem Kunden unter
                  anderem die Kontaktmöglichkeit über den Messenger-Dienst
                  WhatsApp. WhatsApp ist ein Dienst der WhatsApp Ireland
                  Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2,
                  Irland, nachfolgend WhatsApp, eine Tochtergesellschaft von
                  Facebook.
                </p>
                <p>
                  Durch die Kommunikation des Nutzers mit dem Anbieter über
                  WhatsApp erhält sowohl der Anbieter, als auch WhatsApp die
                  Mobilrufnummer des Nutzers und die Information, dass der
                  Nutzer den Anbieter kontaktiert hat.
                </p>
                <p>
                  Die vorgenannten Daten werden von WhatsApp auch an Server von
                  Facebook in den USA weitergeleitet und von WhatsApp und
                  Facebook entsprechend der WhatsApp-Datenschutzrichtlinie
                  verarbeitet, was auch die Verarbeitung zu deren eigenen
                  Zwecken, wie der Verbesserung des WhatsApp-Dienstes,
                  beinhaltet.
                </p>
                <p>_____________________</p>
                <p>
                  <strong
                    >Die USA verfügen gegenwärtig nach Ansicht der
                    Datenschutzaufsichtsbehörden allerdings nicht über ein
                    angemessenes Datenschutzniveau. Es bestehen allerdings
                    sogenannte Standardvertragsklauseln:</strong
                  >
                </p>
                <p>
                  <a
                    href="https://faq.whatsapp.com/general/about-standard-contractual-clauses"
                    rel="nofollow noopener"
                    target="_blank"
                    >https://faq.whatsapp.com/general/about-standard-contractual-clauses</a
                  >
                </p>
                <p>
                  <strong
                    >Jedoch sind dies privatrechtliche Vereinbarungen und haben
                    daher keine direkte Auswirkung auf die Zugriffsmöglichkeiten
                    der Behörden in den USA.</strong
                  >
                </p>
                <p>_____________________</p>
                <p>
                  Näheres zum Zweck und Umfang der Datenerhebung und der
                  weiteren Verarbeitung dieser Daten durch WhatsApp und Facebook
                  sowie diesbezügliche Rechte und Einstellungsmöglichkeiten zum
                  Schutz der Privatsphäre sind in der Datenschutzrichtlinie von
                  WhatsApp enthalten:
                </p>
                <p>
                  <a
                    href="https://www.whatsapp.com/legal/#privacy-policy"
                    rel="nofollow noopener"
                    target="_blank"
                    >https://www.whatsapp.com/legal/#privacy-policy</a
                  >.
                </p>
                <p>
                  Rechtsgrundlage dieser Verarbeitungen und der Übermittlung an
                  WhatsApp ist Art. 6 Abs. 1 S. 1 b. DSGVO, soweit die
                  Kontaktaufnahme eine bestehende Vertragsbeziehung betrifft
                  oder der Anbahnung einer solchen Vertragsbeziehung dient.
                  Sollte die Kontaktaufnahme nicht aufgrund der vorstehenden
                  Zwecke erfolgen, ist Rechtsgrundlage Art. 6 Abs. 1 lit. f
                  DSGVO. Das berechtigte Interesse des Anbieters besteht an der
                  Verbesserung der Servicequalität.
                </p>

                <p>
                  <a
                    href="https://www.generator-datenschutzerklärung.de"
                    target="_blank"
                    rel="noopener"
                    >Muster-Datenschutzerklärung</a
                  >
                  der
                  <a
                    href="https://www.ratgeberrecht.eu/datenschutz/datenschutzerklaerung-generator-dsgvo.html"
                    target="_blank"
                    rel="noopener"
                    >Anwaltskanzlei Weiß &amp; Partner</a
                  >
                </p>
              </div>
            </div>
          </div>
          <!-- /.tab-content -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsConditionArea",
  data() {
    return {
      terms_data: [
        {
          id: "opt1",
          active: true,
          title: "Impressum",
          p1: "Gesetzliche Offenlegung ",
          p2: "Informationen gemäß § 5 TMG",
          date: "Aktualisiert am: 05.05.2023",
          comp: "Ferienwohnung Jonovic",
          add1: "Put-Straze",
          add2: "23234, Vir",
          add3: "Kroatien",
          // add4: "Steuernummer:",
          // add5: "74037/38818",
          add6: "Ansprechpartner",
          add7: "Korina Jonovic",
          // add8: "Florian De Felice",
          add9: "Kontakt",
          add10: "info@ferienwohnung-jonovic.de",
          add11: "https://ferienwohnung-jonovic.de",
          title_1: "1. Haftung für Inhalte",
          text_1:
            "Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.",
          title_2: "2. Haftung für Links ",
          text_2:
            "Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.",
          title_3: "3. Urheberrecht",
          text_3:
            "Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.",
        },
        {
          id: "opt2",
          title: "Datenschutzerklärung",
          date: "Aktualisiert am: 05.05.2023",
        },
      ],
    };
  },
};
</script>
