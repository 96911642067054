<template>
  <div class="fancy-text-block-seven">
    <div class="bg-wrapper no-bg pt-0">
      <div class="container">
        <div class="pb-40">
          <div class="row">
            <div class="col-xl-11">
              <div class="row justify-content-between">
                <div
                  v-for="item in feature_data"
                  :key="item.id"
                  class="col-md-6 aos-init aos-animate"
                  data-aos="fade-up"
                  :data-aos-delay="item.delay"
                >
                  <div class="block-style-twentyEight text-center mt-40">
                    <div
                      class="icon d-flex justify-content-center align-items-end"
                    >
                      <!-- <img :src="item.icon" alt="" /> -->
                    </div>
                    <h4 class="font-gordita">{{ item.title }}</h4>
                    <p>{{ item.subtitle }}</p>
                  </div>
                  <!-- /.block-style-twentyEight -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.bg-wrapper -->
  </div>
</template>

<script>
export default {
  name: "FancyTextBlock",
  components: {},
  data() {
    return {
      startCounter: false,
      counterData: [
        {
          id: 1,
          color: "counter-box-one color-one",
          value: 100,
          title: "Sonne",
        },
        {
          id: 2,
          color: "counter-box-one color-two",
          value: 100,
          title: "Strand",
        },
        {
          id: 3,
          color: "counter-box-one color-three",
          value: 100,
          title: "Viel(es) Mee(h)r",
        },
        {
          id: 4,
          color: "counter-box-one color-three",
          value: 100,
          title: "Viel(es) Mee(h)r",
        },
      ],
      feature_data: [
        {
          id: 1,
          icon: require(`@/assets/images/icon/186.svg`),
          title: "300 Sonnentage",
          subtitle:
            "... im Jahr machen VIR auch in der Nebensaison zu einem reizvollen Urlaubsziel!",
        },
        {
          id: 2,
          icon: require(`@/assets/images/icon/187.svg`),
          title: "28 Sand- und Kiesstrände",
          subtitle: "... lassen das Urlauberherz höher schlagen!",
          delay: "100",
        },
        {
          id: 3,
          icon: require(`@/assets/images/icon/188.svg`),
          title: "Kristallklares Wasser",
          subtitle: "... macht ausgedehntes Baden oder Tauchen zum Genuss!",
          delay: "200",
        },
        {
          id: 4,
          icon: require(`@/assets/images/icon/188.svg`),
          title: "Beeindruckende Landschaften",
          subtitle:
            "... laden zum Wandern, Radfahren und Seelebaumeln lassen ein!",
          delay: "300",
        },
      ],
    };
  },
  methods: {
    onChange(waypointState) {
      if (waypointState.going === "IN") {
        this.startCounter = true;
      }
    },
  },
};
</script>
