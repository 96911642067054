<template>
  <div id="home" class="anchor" />
  <div class="main-page-wrapper">
    <Header />
    <HeroArea />
    <FancyTextBlock />
    <VorgeschmackDivider />
    <PortfolioSlider />
    <AktivitätenDivider />
    <Aktivitäten />
    <WohnungDivider />
    <FancyPortfolio id="portfolio" />
    <AusstattungDivider />
    <Ausstattung />

    <div id="contact" class="anchor" />
    <div class="fancy-hero-six wa-bg-brand" style="padding: 45px 0 45px">
      <div class="container">
        <h3
          style="color: white; font-size: 3.75rem; font-weight: 100"
          class="wa-font-3"
        >
          Ihre Nachricht
        </h3>
      </div>
    </div>
    <ContactStyle />
    <Footer />
  </div>
</template>

<script>
import ContactStyle from "./contact-style.vue";
import FancyTextBlock from "./fancy-text-block.vue";
import FancyPortfolio from "./Fancy-portfolio.vue";
import Header from "../common/Headers/Header.vue";
import HeroArea from "./HeroArea.vue";
import WohnungDivider from "./wohnung-divider.vue";
import AusstattungDivider from "./ausstattung-divider.vue";
import Ausstattung from "./ausstattung.vue";
import AktivitätenDivider from "./aktivitäten-divider.vue";
import Aktivitäten from "./aktivitäten.vue";
import VorgeschmackDivider from "./vorgeschmack-divider.vue";

import Footer from "./Footer.vue";
import PortfolioSlider from "./portfolio-slider.vue";

export default {
  name: "HomeMain",
  components: {
    FancyTextBlock,
    FancyPortfolio,
    Header,
    HeroArea,
    ContactStyle,
    AktivitätenDivider,
    Aktivitäten,
    WohnungDivider,
    AusstattungDivider,
    Ausstattung,
    Footer,
    VorgeschmackDivider,
    PortfolioSlider,
  },
};
</script>
