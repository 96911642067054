<template>
  <TermsConditionMain />
</template>

<script>
import TermsConditionMain from "../components/terms-condition";

export default {
  name: "TermsCondition",
  components: { TermsConditionMain },
};
</script>
