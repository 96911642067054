export default {
  data() {
    return {
      portfolioItems: [
        //  v1
        {
          id: 1,
          img: require(`@/assets/images/img_fj/outside/webp/1.webp`),
        },
        {
          id: 2,
          img: require(`@/assets/images/img_fj/outside/webp/2.webp`),
        },
        {
          id: 3,
          img: require(`@/assets/images/img_fj/outside/webp/3.webp`),
        },
        {
          id: 4,
          img: require(`@/assets/images/img_fj/outside/webp/4.webp`),
        },
        {
          id: 5,
          img: require(`@/assets/images/img_fj/outside/webp/5.webp`),
        },
        {
          id: 6,
          img: require(`@/assets/images/img_fj/outside/webp/6.webp`),
        },
        {
          id: 7,
          img: require(`@/assets/images/img_fj/outside/webp/7.webp`),
        },
        {
          id: 8,
          img: require(`@/assets/images/img_fj/outside/webp/8.webp`),
        },
      ],
    };
  },
};
