<template>
  <div class="fancy-text-block-seven mt-20 md-mt-10">
    <div class="bg-wrapper no-bg">
      <div class="container">
        <div>
          <div
            v-for="(section, index) in feature_data"
            :key="index"
            class="row"
          >
            <div class="col-xl-11">
              <div class="row justify-content-between">
                <div
                  v-for="(section_item, index) in section.section"
                  :key="index"
                  class="col-md-6 aos-init aos-animate"
                  data-aos="fade-up"
                  :data-aos-delay="section_item.delay"
                >
                  <div class="block-style-twentyEight text-center">
                    <h4 class="font-gordita">{{ section_item.title }}</h4>
                    <ul
                      :class="section_item.no_check_mark ? 'no-check-mark' : ''"
                    >
                      <li
                        class="wa-icon-set"
                        v-for="(item, index) in section_item.items"
                        :key="index"
                      >
                        <img :src="item.icon" alt="" />
                        <span style="font-style: italic">{{ item.label }}</span>
                      </li>
                    </ul>
                  </div>
                  <!-- /.block-style-twentyEight -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.bg-wrapper -->
  </div>
</template>

<script>
export default {
  name: "FancyTextBlock",
  components: {},
  data() {
    return {
      startCounter: false,
      counterData: [
        {
          id: 1,
          color: "counter-box-one color-one",
          value: 100,
          title: "liebevoll",
        },
        {
          id: 2,
          color: "counter-box-one color-two",
          value: 100,
          title: "einzigartig",
        },
        {
          id: 3,
          color: "counter-box-one color-three",
          value: 100,
          title: "personalisiert",
        },
      ],
      feature_data: [
        {
          section: [
            {
              id: 1,
              items: [
                {
                  label: "Angeln",
                  icon: new URL(
                    "@/assets/images/mdi-icons/fish.png",
                    import.meta.url
                  ),
                },
                {
                  label: "Reiten",
                  icon: new URL(
                    "@/assets/images/mdi-icons/horse-human.png",
                    import.meta.url
                  ),
                },
                {
                  label: "Tennis",
                  icon: new URL(
                    "@/assets/images/mdi-icons/tennis.png",
                    import.meta.url
                  ),
                },
                {
                  label: "Wandern / Bergsteigen",
                  icon: new URL(
                    "@/assets/images/mdi-icons/hiking.png",
                    import.meta.url
                  ),
                },
                {
                  label: "Schwimmen",
                  icon: new URL(
                    "@/assets/images/mdi-icons/swim.png",
                    import.meta.url
                  ),
                },
              ],
            },
            {
              id: 2,
              items: [
                {
                  label: "Fahrradfahren",
                  icon: new URL(
                    "@/assets/images/mdi-icons/bike.png",
                    import.meta.url
                  ),
                },
                {
                  label: "Segeln",
                  icon: new URL(
                    "@/assets/images/mdi-icons/sail-boat.png",
                    import.meta.url
                  ),
                },
                {
                  label: "Wasserski",
                  icon: new URL(
                    "@/assets/images/mdi-icons/ski-water.png",
                    import.meta.url
                  ),
                },
                {
                  label: "Nordic Walking",
                  icon: new URL(
                    "@/assets/images/mdi-icons/ski-cross-country.png",
                    import.meta.url
                  ),
                },
                {
                  label: "Tauchen",
                  icon: new URL(
                    "@/assets/images/mdi-icons/diving-scuba.png",
                    import.meta.url
                  ),
                },
              ],
              delay: "100",
            },
          ],
        },
      ],
    };
  },
  methods: {
    onChange(waypointState) {
      if (waypointState.going === "IN") {
        this.startCounter = true;
      }
    },
  },
};
</script>

<style lang="css">
.list-item-one li {
  font-size: 1rem;
}

.list-item-one li:before {
  content: url("@/assets/images/assets/check-mark.svg");
  position: relative;
  margin-right: 15px;
}

.no-check-mark > li:nth-last-child(1)::before,
.no-check-mark > li:nth-last-child(2)::before {
  content: "!";
  color: red;
  position: relative;
  margin-right: 15px;
}

.wa-icon-set {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wa-icon-set > img {
  filter: opacity(0.6);
  margin-right: 1rem;
  height: 20px;
}

.wa-icon-set > span {
  font-size: 20px;
  padding: 10px 0;
}
</style>
