<template>
  <Footer />
</template>

<script>
import Footer from "../common/Footers/Footer.vue";

export default {
  name: "FooterWrapper",
  components: {
    Footer,
  },
};
</script>
