<template>
  <div class="fancy-text-block-seven mt-20 md-mt-10">
    <div class="bg-wrapper no-bg">
      <div class="container">
        <div>
          <div
            v-for="(section, index) in feature_data"
            :key="index"
            class="row"
          >
            <div class="col-xl-11 m-auto">
              <div class="row justify-content-between">
                <div
                  v-for="(section_item, index) in section.section"
                  :key="index"
                  class="col-md-4 aos-init aos-animate"
                  data-aos="fade-up"
                  :data-aos-delay="section_item.delay"
                >
                  <div class="block-style-twentyEight text-center">
                    <h4 class="font-gordita">{{ section_item.title }}</h4>
                    <ul
                      class="list-item-one"
                      :class="section_item.no_check_mark ? 'no-check-mark' : ''"
                    >
                      <li
                        v-for="(item, index) in section_item.items"
                        :key="index"
                      >
                        {{ item }}
                      </li>
                    </ul>
                  </div>
                  <!-- /.block-style-twentyEight -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.bg-wrapper -->
  </div>
</template>

<script>
export default {
  name: "FancyTextBlock",
  components: {},
  data() {
    return {
      startCounter: false,
      counterData: [
        {
          id: 1,
          color: "counter-box-one color-one",
          value: 100,
          title: "liebevoll",
        },
        {
          id: 2,
          color: "counter-box-one color-two",
          value: 100,
          title: "einzigartig",
        },
        {
          id: 3,
          color: "counter-box-one color-three",
          value: 100,
          title: "personalisiert",
        },
      ],
      feature_data: [
        {
          section: [
            {
              id: 1,
              title: "Küche",
              items: [
                "Backofen",
                "Elektr. Kochplatte",
                "Gefrierfach",
                "Geschirrspüler",
                "Herd",
              ],
            },
            {
              id: 2,
              title: "Außenbereich",
              items: [
                "Balkon",
                "Garten",
                "Gartenmöbel",
                "Grill",
                "Grillplatz",
                "Parkplatz",
                "Terrasse",
              ],
              delay: "100",
            },
            {
              id: 3,
              title: "Wäsche & Zubehör",
              items: ["Bettwäsche", "Handtücher", "Waschmaschine"],
              delay: "200",
            },
          ],
        },
        {
          section: [
            {
              id: 4,
              title: "Heizen & Kühlen",
              items: ["Heizung", "Klimaanlage", "Warmwasser"],
            },
            {
              id: 5,
              title: "Kommunikation",
              items: ["Internet / WLAN"],
              delay: "100",
            },
            {
              id: 6,
              title: "Audio & Video",
              items: ["Radio", "Satelitenantenne", "TV"],
              delay: "200",
            },
          ],
        },
        {
          section: [
            {
              id: 7,
              title: "Besonderheiten",
              no_check_mark: true,
              items: [
                "Kinder willkommen",
                "Senioren geeignet",
                "Langzeitmiete möglich",
                "Rollstuhl ungeeignet",
                "Tiere/Hunde nach Absprache",
              ],
            },
          ],
        },
      ],
    };
  },
  methods: {
    onChange(waypointState) {
      if (waypointState.going === "IN") {
        this.startCounter = true;
      }
    },
  },
};
</script>

<style lang="css">
.list-item-one li {
  font-size: 1rem;
}

.list-item-one li:before {
  content: url("@/assets/images/assets/check-mark.svg");
  position: relative;
  margin-right: 15px;
}

.no-check-mark > li:nth-last-child(1)::before,
.no-check-mark > li:nth-last-child(2)::before {
  content: "!";
  color: red;
  position: relative;
  margin-right: 15px;
}
</style>
