<template>
  <div class="fancy-hero-six" style="padding: 45px 0 20px">
    <div class="container">
      <h3
        style="color: #ff9e68; font-size: 3.75rem; font-weight: 100"
        class="wa-font-3"
      >
        Ein Ausblick
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "FancyHero",
};
</script>
