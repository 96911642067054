<template>
  <div id="appartment" class="anchor" />
  <div class="fancy-hero-six wa-bg-brand" style="padding: 45px 0 45px">
    <div class="container">
      <h3
        style="color: white; font-size: 3.75rem; font-weight: 100"
        class="wa-font-3"
      >
        Ferienwohnung
      </h3>
    </div>
  </div>

  <div class="wa-divider-text">
    <p style="font-size: 1.25rem; text-align: center; line-height: 2.5rem">
      Die Ferienwohnung befindet sich in der oberen Etage des Hauses. Sie
      überzeugt durch Komfort und eine tolle Lage. Ihre Vermieter bewohnen die
      untere Etage und sind aufgrund ihrer Gastfreundschaft eine wahre
      Bereicherung für ihren Urlaub!

      <br />
      <br />

      Von der Ferienwohnung bis zum Strand sind es weniger als 100 m und bis zur
      Ortsmitte von Vir ca. 2 km. Einkaufsmöglichkeiten finden Sie in der Nähe
      oder im ca. 25km entfernten Zadar.
    </p>
  </div>
</template>

<script>
export default {
  name: "FancyHero",
};
</script>
