<template>
  <div id="activities" class="anchor" />
  <div class="fancy-hero-six wa-bg-brand" style="padding: 45px 0 45px">
    <div class="container">
      <h3
        style="color: white; font-size: 3.75rem; font-weight: 100"
        class="wa-font-3"
      >
        Aktivitäten
      </h3>
    </div>
  </div>

  <div class="wa-divider-text">
    <p style="font-size: 1.33rem; text-align: center; line-height: 2.5rem">
      Die Insel Vir liegt rund 25 km nordwestlich von Zadar im schönen
      Norddalmatien. Diese Lage lässt in Bezug auf die Wahl Ihrer Aktivitäten
      kaum Wünsche offen. Im Folgenden nur ein paar kleine Anregungen ...
    </p>
  </div>
</template>

<script>
export default {
  name: "FancyHero",
};
</script>
