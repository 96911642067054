<template>
  <div class="main-page-wrapper overflow-hidden">
    <Header :menu="[{ label: 'Home', to: '/' }]" />
    <TermsConditionArea />
  </div>
</template>

<script>
import Header from "../common/Headers/Header.vue";
import TermsConditionArea from "./terms-condition-area-jonovic.vue";

export default {
  name: "TermsConditionMain",
  components: { Header, TermsConditionArea },
};
</script>
